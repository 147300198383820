import React from "react"
import { isMobile } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import FAQs from "../components/faqs/faqs"
// import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Política de privacidad"/>;
// const footerDesc = "";
const contentMaxWidth = "980px";

const dataFilters = [
    {
        id: "general",
        label: "General"
    }
];

const dataFAQs = [
    {
        title: "¿Cuánto dura el reto?",
        description: "<p>21 días en las que recibirás lecciones en vivo así como actividades a realizar.</p>",
        categories: ["general"]
    },
    {
        title: "¿Dónde será el reto?",
        description: "<p>En la comunidad privada de Facebook completamente en vivo.</p>",
        categories: ["general"]
    },
    {
        title: "¿Qué pasa si no puedo estar en vivo?",
        description: "<p>Las lecciones quedarán grabadas para que puedas ponerte al corriente.</p>",
        categories: ["general"]
    },
    {
        title: "¿Qué pasa al terminar el reto?",
        description: "<p>El grupo permanecerá abierto un par de semanas, en cuanto a ti será el comienzo de un aprendizaje y crecimiento perpetuo, la decisión está en tus manos.</p>",
        categories: ["general"]
    },
    {
        title: "¿Puedo tomar el reto sino soy un millenial?\n",
        description: "<p>¡Definitivamente! será un reto de mucho aprendizaje y actualización, además podrás presumir que ya entiendes a los millenials 😎</p>",
        categories: ["general"]
    }
];

const FinancialRevolution = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const launchFormHandler = () => {
        window.open("https://swiy.io/reto-payment", "_blank");
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const viewIntroHandler = () => {
        const url = "https://player.vimeo.com/video/512764526";

        if (isMobile) {
            window.location.href = url;
        } else {
            window.scroll({ top: 0, left: 0, behavior: "smooth" });

            const modal = {
                display: true,
                content: <IFrame file={url} title="Revolución Financiera 2021" height="500px"/>
            };

            dispatch(ACTIONS.showModal(modal))
        }
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    const categoryFAQsHandler = (id) => {
        const { categoryFAQs } = appState;
        const categoryId = categoryFAQs.find(item => item === id);

        if (categoryId) {
            const index = categoryFAQs.indexOf(categoryId);

            if (index > -1 && categoryFAQs.length > 1) {
                categoryFAQs.splice(index, 1);
            }

        } else {
            // categoryFAQs.push(id)
            categoryFAQs[0] = id
        }

        dispatch(ACTIONS.setCategoryFAQs(categoryFAQs));
    };

    return (
        <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} title="Revolución Financiera 2021: Reto Millenial Money. Descubre las reglas del juego del dinero y crea un plan para lograr tu independencia financiera." description="Reto Millenial Money. Un reto de 21 días de finanzas personales iniciando este 1ro. de Marzo. Todo se encuentra en una constante búsqueda de estabilidad e independencia financiera, pero solo unos pocos la logran. ¿Qué significa 'meta financiera' para cada individuo? ¿Cómo podemos lograrlo?"/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
            }
            <MainSection title={"Revolución Financiera 2021: \nReto Millenial Money"} description="<h3>Descubre las reglas del juego del dinero y crea un plan para lograr tu independencia financiera.</h3>" position="left" image="hero-financial-revolution.png" actionLabel="Únete ahora" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickRevFin">
                <AppBar pathname={appState.path}/>
            </MainSection>
            <PageSection>
                <div style={{marginTop: "-80px", zIndex: "1"}}>
                    <Card
                        subtitle="Reto de 21 días de finanzas personales iniciando este 1ro. de Marzo"
                        description="<p>Todos nosotros buscamos estabilidad e independencia financiera, pero la realidad es que solo unos pocos lo logramos. ¿Qué significa libertad financiera? y cómo la podemos lograr</p>"
                        descriptionColor="#363636"
                        image="millenial-money-challenge.svg"
                        imageWidth="240px"
                        imageHeight="160px"
                        actionLabel="Ver intro"
                        action={viewIntroHandler}
                        actionVariant="outlined"
                        bgColor="#fff"
                        horizontal
                        padding
                        shadow
                    />
                </div>
            </PageSection>
            <PageSection title="¿Tienes un plan financiero para alcanzar las metas y propósitos de tu vida? " maxWidth={contentMaxWidth}>
                <Card
                    title="¿Qué es la indepencia financiera?"
                    description="<p>Todos en esta vida buscamos la estabilidad y la independencia financieras, pero solo unos pocos la logran.</p><p>Para algunos, puede ser saldar una deuda que tienen años arrastrando. Para otros conseguir su primer patrimonio en pareja. Empezar a invertir en activos, crecer un negocio para no tener que trabajar toda la vida, etc.</p>"
                    descriptionColor="#363636"
                    image="financial-problem-ahal.svg"
                    imageWidth="400px"
                    imageHeight="300px"
                    marginTopContent="40px"
                    horizontal
                    padding
                />
                <Card
                    title="¿Para quién es este reto?"
                    description="<p>Si no has podido capitalizar tu talento.</p><p>Si por más que trabajas sientes que no te alcanza el dinero, crees que has tomado malas decisiones financieras o simplemente lo has hecho bien, pero quieres blindarte para el futuro y escalar como lo estás haciendo.</p><p>Entonces este reto está diseñado para ti.</p>"
                    descriptionColor="#363636"
                    image="who-is-ahal.svg"
                    imageWidth="420px"
                    imageHeight="310px"
                    marginTopContent="40px"
                    reverseDirection
                    horizontal
                    padding
                />
                <Card
                    title="Al finalizar el reto tendras"
                    description="<ul><li>Un presupuesto para flujo mensual</li><li>Un análisis de ingresos y gastos</li><li>Aprender a tomar decisiones financieras para vivir establemente</li><li>Aprende a organizar y adaptar tus finanzas para que, a pesar del contexto actual, logres que el dinero te alcance y sea suficiente para alcanzar la calidad de vida que deseas.</li></ul>"
                    descriptionColor="#363636"
                    image="rewards-ahal.svg"
                    imageWidth="405px"
                    imageHeight="305px"
                    marginTopContent="20px"
                    horizontal
                    padding
                />
                <Card
                    title="La mentora detrás de este reto"
                    description={`<p>"Y como tú, no nací en cuna de oro, estuve becada prácticamente toda mi vida, y diversas situaciones me orillaron a independizarme muy joven tuve que aprender a prueba y error como manejar mis finanzas para poder lograr la estabilidad que buscaba...</p><p>Durante ese viaje trabajé como consultora financiera, experta en sector turismo, fintech, ayudando a pymes y corporativos a crecer y mejorar sus negocios con mejores decisiones financieras</p><p>Si puedo ayudar a mejorar las finanzas de una multinacional, puedo ayudarte a ti."</p><p><strong>Ludi Córdoba</strong></p>`}
                    descriptionColor="#363636"
                    image="ludi-cordoba.png"
                    imageWidth="420px"
                    imageHeight="320px"

                    reverseDirection
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection maxWidth={contentMaxWidth} bgColor="#F3F4F6">
                <Card
                    title="¿Quién lanza este reto?"
                    description="<p>Este reto posible gracias a Ahal Academy y EVVA Finanzas.</p><p>En Ahal Academy queremos ayudar a que puedas tomar mejores decisiones financieras y de negocios para vivir una vida con bienestar y libertad</p><p>EVVA simplifica la vida financiera de PyMEs, Startups y Freelancers. Han lanzado una plataforma digital para conectarte a los usuarios servicios financieros</p>"
                    descriptionColor="#363636"
                    image="logos-ahal-evva.png"
                    imageWidth="400px"
                    imageHeight="285px"
                    marginTopContent="20px"
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection title="Preguntas frecuentes" maxWidth={contentMaxWidth} >
                <FAQs items={dataFAQs} filters={dataFilters} categoryFAQsHandler={categoryFAQsHandler} categoryFAQs={appState.categoryFAQs}/>
            </PageSection>
            <SecondarySection
                title="1ro. de Marzo 2021 a las 7:00 p.m."
                shortDescription="Únete ahora al reto de 21 días de finanzas personales."
                position="center"
                image="evva-lego-bricks.png"
                actionLabel="Únete ahora"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickRevFin"
            />
        </Layout>
    )
};

export default FinancialRevolution;